.firstImage {
    position: absolute;
    top: -100px;
    left: 80px;
    max-width: 400px;
}

.secondImage {
    max-width: 400px;
    position: absolute;
    top: 400px;
    left: 200px;
    z-index: -1;
}

li::before {
    content: "" !important;
    display: inline-block !important; 
    width: 1em;
}
.one li::before{
    content: "1.--" !important;
    display: inline-block !important; 
    width: 1em;
}
