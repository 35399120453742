@tailwind base;
@tailwind components;
@tailwind utilities;

@import "animate.css";

@import "./../node_modules/swiper/swiper.css";
@import "primereact/resources/themes/lara-light-cyan/theme.css";

@layer base {
    @font-face {
        font-family: "Century Gothic Regular";
        src: url("./assets/fonts/Century Gothic.ttf");
    }
    @font-face {
        font-family: "Century Gothic Bold";
        src: url("./assets/fonts/Century Gothic Bold.ttf");
    }
    @font-face {
        font-family: "Blanch Regular";
        src: url("./assets/fonts/BLANCH_CONDENSED.otf");
    }
    @font-face {
        font-family: "Blanch Light";
        src: url("./assets/fonts/BLANCH_CAPS_LIGHT.otf");
    }
    @font-face {
        font-family: "Blanch Bold";
        src: url("./assets/fonts/BLANCH_CONDENSED_INLINE.otf");
    }
    @font-face {
        font-family: "LiberationSans-Regular";
        src: url("./assets/fonts/LiberationSans-Regular.ttf");
    }
}

:root {
    --aha-green: #12a9ae;
    --aha-orange: #F7941E;
    --aha-black: #444A64;
    --aha-light-blue: #DBEDF2;
    --aha-light-blue-500: #BEDDDC;
  }

.ReactModalPortal {
    z-index: 200;
    position: relative;
}

.container {
    @apply px-4;
}

.test_class {
    position: absolute;
    background-color: transparent;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 20%;
    cursor: pointer;
}

.card_btn_container {
    position: absolute;
    right: 5%;
    top: 6.6%;
    width: 5.5%;
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: 7px;
    align-items: center;
}

.card_btn {
    cursor: pointer;
    transition: all 0.2s;
}

.card_btn:hover {
    scale: 1.1;
}

#subscription {
    background: rgb(190, 221, 220);
    background: linear-gradient(
        0deg,
        rgba(218, 235, 236, 1) 50%,
        rgba(190, 221, 220, 1) 50%
    );
}

.loader {
    width: 125px;
    height: 125px;
    position: relative;
    margin: auto;
  }
  .loader .image {
    width: 100%;
    height: 125px;
    font-size: 40px;
    text-align: center;
    transform-origin: bottom center;
    animation: 2s rotate infinite;
    opacity: 0;
  }
  .loader span {
    display: block;
    width: 100%;
    text-align: center;
    position: relative;
    bottom: 0;
  }
  
  @keyframes rotate{
    0% {
      transform: rotate(90deg);
    }
    10% {
      opacity: 0;
    }
    35% {
      transform: rotate(0deg);
      opacity: 1;
    }
    65% {
      transform: rotate(0deg);
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      transform: rotate(-90deg);
    }
  }
