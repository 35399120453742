.get_started_title {
  line-height: 3.5rem;
  max-width: 440px;
  text-align: center;
}

.custom-bullet::before {
    content: "•" !important; 
    color: var(--aha-green);
    display: inline-block; width: 1em;
    margin-left: -1em
}

.whatis__ul{
  line-height: 50px;
}

@media (max-width: 1500px) and (min-width: 1310px) {
  .whatis__ul {
    line-height: 35px;
  }
}
@media (max-width: 1309px) and (min-width: 1024px) {
  .whatis__ul {
    line-height: 1.5rem;
    padding-top: 1.5rem;
  }
}