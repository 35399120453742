.sectionTwo {
    .title {
        width: 300px;
        margin: 0 auto;
    }

    .icon {
        width: 300px;
        margin: 0 auto;
    }

    .description {
        // width: 400px;
        margin: 0 auto;
    }
}
